<template>
  <div class="container mt-5">
    <div class="en-tc" v-if="$i18n.locale === 'en'">
      <h1>Terms and conditions</h1>
      <p>I am aware that:</p>
      <ol>
        <li>
          the report obtained is not an investment recommendation or any
          solicitation to buy or sell published shares
        </li>
        <li>
          the site owner disclaims any responsibility for the use of published
          information
        </li>
        <li>
          The use of published information and reports is my sole responsibility
          and I cannot claim any damages from the site owner or any third
          parties.
        </li>
      </ol>
    </div>
    <div class="cs-tc" v-else>
      <h1>Podmínky použití</h1>
      <p>Jsem si vědom/a, že:</p>
      <ol>
        <li>
          získaný report není investiční doporučení ani jakákoliv výzva k nákupu
          či prodeji uvedených akcií
        </li>
        <li>
          provozovatel webu odmítá jakoukoliv odpovědnost za využívání
          zveřejněných informací
        </li>
        <li>
          použití zveřejněných informací a reportů je čistě na mou odpovědnost a
          nemohu na provozovateli webu ani jakýchkoliv třetích stranách
          nárokovat jakoukoliv škodu.
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
export default {
  name: "TermsAndConditions",
};
</script>

<style scoped>
</style>